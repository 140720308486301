import React, { useState, useEffect, useCallback, Fragment } from "react"
import { Link, navigate } from "gatsby"
import { useParams } from "@reach/router"
import { Row, Col, Form, Button, Input, message, Spin } from "antd"
import { reservationService } from "../services/reservations"
import SEO from "../components/seo"
import moment from "moment-timezone"
import { useTranslation } from "react-i18next";
import { DocumentCard } from "cmt-ui-kit"
import { documentTypeName } from "../helpers/handlers"

const { format } = require("rut.js")

const CheckExams = () => {
  const [loading, setLoading] = useState(false)
  const [examDetails, setExamDetails] = useState()
  const [examToken, setExamToken] = useState()
  const [documentType, setDocumentType] = useState();
  const countryCode = `${process.env.GATSBY_COUNTRY_VALUE}`
  const [form] = Form.useForm()
  const { t } = useTranslation();
  const params = useParams()


  const handleDocumentType = () => {
    if (countryCode === 'CL') {
      form.setFieldsValue({ documentType: 'rut' })
      setDocumentType('rut')
    } else if (countryCode === 'PE') {
      form.setFieldsValue({ documentType: 'ruc' })
      setDocumentType('ruc')
    }
  }

  useEffect(() => {
    if (!documentType) {
      handleDocumentType()
    }
  }, [])


  const findExamsByToken = useCallback(async () => {
    await onFinish({ token: params.token, type: 'token' })
  }, [])

  useEffect(() => {
    if (params && params.token) {
      findExamsByToken()
    }
  }, [findExamsByToken])


  const onFinish = async (values) => {
    let queryData = {
      token: values.token,
    }
    setExamToken(values.token)

    if (values.documentType && values.documentType !== 'rut') {
      queryData = {
        ...queryData,
        document_number: values.documentNumber,
      }
    } else if (values.documentType && values.documentType === 'rut') {
      const unformatedRut = format(values.documentNumber, { dots: false })
      queryData = {
        ...queryData,
        document_number: unformatedRut,
      }
    } else {
      queryData = {
        ...queryData,
        type: values.type,
      }
    }


    try {
      setLoading(true)
      const response = await reservationService.examChecker(queryData)

      if (response.length > 0) {
        setExamDetails(response)
      }
    } catch (err) {
      message.error({
        content: "Error al buscar examen. Intenta nuevamente.",
        style: {
          marginTop: '45vh',
        },
      })
      console.error('Error al buscar examen: ', err);
    } finally {
      setLoading(false)
    }
  }

  const capitalizeText = (text) => {
    let newText = text.toLowerCase()
    return newText.replace(/\b(\w)/g, (s) => s.toUpperCase())
  }

  const formatDate = (date) => {
    if (!date) return;

    if (isNaN(Date.parse(date))) return date;

    return moment(date, "YYYY-MM-DDTHH:mm:ssZ")
      .tz("Etc/UTC")
      .format("DD-MM-YYYY")
  }

  const handleCheckOtherExam = () => {
    setExamToken()
    form.resetFields()
    handleDocumentType()
    navigate('/app/checker/')
  }

  const schemaExtraData = [{
    "@type": "Organization",
    "logo": "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    "sameAs": [
      "https://www.facebook.com/CentroMedicoDelTrabajador",
      "https://www.linkedin.com/company/centro-medico-del-trabajador"
    ],
  }]


  return (
    <Fragment>
      <SEO
        title={t('seo.checker.title')}
        description={t('seo.checker.description')}
        dataLayer={{
          userid: "",
          pageId: "",
          pageType: "",
          category: "",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Spin className="cmt-spin" spinning={loading}>
        <Row
          justify="center"
          className="check-exams-container mt-lg-20 mt-xs-5 mb-70"
        >
          <Col lg={22} xs={22}>
            {
              (examDetails && examToken) ? (
                examDetails.map((data, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Row justify="center">
                        <Col lg={24} xs={24}>
                          <h1 className="text-center check-exam-title">
                            {t('checker.formTitle')}
                          </h1>
                          <h2 className="mt-lg-30 mt-xs-5">Examen N° {examToken}</h2>
                        </Col>
                      </Row>
                      <Row
                        className="gray-content-mobile pt-20 pb-20 pl-lg-0 pl-md-0 pl-sm-15 pl-xs-15 pr-xs-15 mt-10"
                      >
                        <Col lg={24} xs={24}>
                          <h3 className="mb-10">{t('checker.patient')}</h3>
                        </Col>
                        <Col lg={20} xs={24}>
                          <Row>
                            <Col lg={8} xs={12}>
                              <Row>
                                <Col lg={24} xs={24}>
                                  <h4 className="center-mobile ml-lg-15 ml-md-15 ml-sm-0 ml-xs-0">
                                    Nombre
                                  </h4>
                                </Col>
                                <Col
                                  lg={24}
                                  xs={24}
                                  className="gray-row pt-lg-10 pt-md-10 pt-sm-0 pt-xs-0 pb-10"
                                >
                                  <p className="center-mobile pl-lg-15 pl-md-15 pl-sm-0 pl-xs-0 mb-0">
                                    {capitalizeText(data.full_name)}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={7} xs={12}>
                              <Row>
                                <Col lg={24} xs={24}>
                                  <h4 className="text-center">{documentTypeName(data.document_type)}</h4>
                                </Col>
                                <Col
                                  lg={24}
                                  xs={24}
                                  className="gray-row pt-lg-10 pt-md-10 pt-sm-0 pt-xs-0 pb-10"
                                >
                                  <p className="text-center mb-0">
                                    {data.document_type === 'rut' ? format(data.document_number) : data.document_number}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={6} xs={12}>
                              <Row justify="center">
                                <Col lg={24} xs={24}>
                                  <h4 className="text-center">Cargo</h4>
                                </Col>
                                <Col
                                  lg={24}
                                  xs={24}
                                  className="gray-row pt-lg-10 pt-md-10 pt-sm-0 pt-xs-0 pb-10"
                                >
                                  <p className="text-center mb-0">
                                    {capitalizeText(data.profession)}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={3} xs={12}>
                              <Row justify="center">
                                <Col lg={24} xs={24}>
                                  <h4 className="text-center">Edad</h4>
                                </Col>
                                <Col
                                  lg={24}
                                  xs={24}
                                  className="gray-row pt-lg-10 pt-md-10 pt-sm-0 pt-xs-0 pb-10"
                                >
                                  <p className="text-center mb-0">{data.age.years}</p>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row
                        className="gray-content-mobile pt-20 pb-20 pl-lg-0 pl-md-0 pl-sm-15 pl-xs-15 pr-lg-0 pr-xs-15 mt-10"
                      >
                        <Col lg={24}>
                          <h3 className="mb-10">Empresa</h3>
                        </Col>
                        <Col lg={20} xs={24}>
                          <Row>
                            <Col lg={12} xs={12}>
                              <Row justify="center">
                                <Col lg={24} xs={24}>
                                  <h4 className="center-mobile ml-lg-15 ml-md-15 ml-sm-0 ml-xs-0">
                                    Nombre
                                  </h4>
                                </Col>
                                <Col
                                  lg={24}
                                  xs={24}
                                  className="gray-row pt-lg-10 pt-md-10 pt-sm-0 pt-xs-0 pb-10"
                                >
                                  <p className="center-mobile pl-lg-15 pl-md-15 pl-sm-0 pl-xs-0 mb-0">
                                    {capitalizeText(data.business_name)}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={12} xs={12}>
                              <Row justify="center">
                                <Col lg={24} xs={24}>
                                  <h4 className="text-center">{documentTypeName(data.business_document_type)}</h4>
                                </Col>
                                <Col
                                  lg={24}
                                  xs={24}
                                  className="gray-row pt-lg-10 pt-md-10 pt-sm-0 pt-xs-0 pb-10"
                                >
                                  <p className="text-center mb-0">
                                    {data.business_document_type === 'rut' ? format(data.business_number) : data.business_number}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row
                        className="gray-content-mobile pt-20 pb-20 pl-lg-0 pl-md-0 pl-sm-15 pl-xs-15 pr-lg-0 pr-xs-15 mt-10 mb-40"
                      >
                        <Col lg={24}>
                          <h3 className="mb-10">Datos de examen</h3>
                        </Col>
                        <Col lg={20} xs={24}>
                          <Row>
                            <Col lg={9} xs={12}>
                              <Row justify="center">
                                <Col lg={24} xs={24}>
                                  <h4 className="center-mobile ml-lg-15 ml-md-15 ml-sm-0 ml-xs-0">
                                    Examen
                                  </h4>
                                </Col>
                                <Col
                                  lg={24}
                                  xs={24}
                                  className="gray-row pt-lg-10 pt-md-10 pt-sm-0 pt-xs-0 pb-10"
                                >
                                  <p className="center-mobile pl-lg-15 pl-md-15 pl-sm-0 pl-xs-0 mb-0">
                                    {data.exam}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={5} xs={12}>
                              <Row justify="center">
                                <Col lg={24} xs={24}>
                                  <h4 className="text-center">Fecha</h4>
                                </Col>
                                <Col
                                  lg={24}
                                  xs={24}
                                  className="gray-row pt-lg-10 pt-md-10 pt-sm-0 pt-xs-0 pb-10"
                                >
                                  <p className="text-center mb-0">
                                    {formatDate(data.admission_date)}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={6} xs={12}>
                              <Row justify="center">
                                <Col lg={24} xs={24}>
                                  <h4 className="text-center">Válido hasta</h4>
                                </Col>
                                <Col
                                  lg={24}
                                  xs={24}
                                  className="gray-row pt-lg-10 pt-md-10 pt-sm-0 pt-xs-0 pb-10"
                                >
                                  <p className="text-center mb-0">
                                    {data.report_expiration_date ? formatDate(data.report_expiration_date) : '-'}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={4} xs={12}>
                              <Row justify="center">
                                <Col lg={24} xs={24}>
                                  <h4 className="text-center">Resultado</h4>
                                </Col>
                                <Col
                                  lg={24}
                                  xs={24}
                                  className="gray-row pt-lg-10 pt-md-10 pt-sm-0 pt-xs-0 pb-10"
                                >
                                  <p
                                    className={
                                      `text-center mb-0 exam-result-state ${(data.state_result === 'Reevaluación' && countryCode === 'CL') ? 'reevaluation' : (data.approved ? "approved" : "disapproved")
                                      }`
                                    }
                                  >
                                    {data.state_result || (data.approved ? "Aprobado" : "No Aprobado")}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row justify="center">
                        <Col
                          xl={5}
                          lg={6}
                          xs={24}
                          className="check-other-exam-button-wrapper mr-lg-15 mr-xs-0 mb-lg-0 mb-md-20 mb-xs-20"
                        >
                          <Button
                            className="cmt-button primary"
                            type="primary"
                            onClick={handleCheckOtherExam}
                            size="large"
                            block
                          >
                            Verificar otro examen
                          </Button>
                        </Col>
                        <Col xl={3} lg={4} xs={24}>
                          <Link to="/">
                            <Button
                              className="cmt-button primary outline"
                              type="primary"
                              size="large"
                              block
                            >
                              Volver al inicio
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )
                })
              ) : (
                <React.Fragment>
                  <Row justify="center" className="check-exams-public-wrapper">
                    <Col lg={24} xs={24}>
                      <h1 className="text-center mb-lg-10 mb-md-20 mb-sm-20 mb-xs-20">
                        {t('checker.formTitle')}
                      </h1>
                      <p className="text-center">
                        {t('checker.verifyExamsText')}
                      </p>
                    </Col>
                  </Row>
                  <Row justify="center" className="form-wrapper mt-20">
                    <Col xxl={6} xl={8} lg={9} md={18} sm={19} xs={24}>
                      <Form
                        form={form}
                        onFinish={onFinish}
                        layout='vertical'
                        className='cmt-form'
                        size='large'
                      >
                        <Row gutter={[0, 20]}>
                          <Col lg={24} xs={24}>
                            <p className="mb-5">Tipo y número de documento empresa</p>
                            <DocumentCard
                              form={form}
                              type='company'
                              countryCode={countryCode}
                              setDocumentType={setDocumentType}
                            />
                          </Col>
                          <Col lg={24} xs={24}>
                            <Form.Item
                              name="token"
                              label='Número de examen'
                              rules={[
                                {
                                  required: true,
                                  message: "Número de examen es requerido",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Ingrese número de examen"
                                className="cmt-input"
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={24} xs={24}>
                            <Row justify="center">
                              <Col lg={8} xs={24}>
                                <Button
                                  className="cmt-button primary"
                                  type="primary"
                                  htmlType="submit"
                                  block
                                >
                                  Consultar
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
          </Col>
        </Row>
      </Spin>
    </Fragment>
  )
}

export default CheckExams